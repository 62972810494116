import React from "react";
import { Link } from 'react-router-dom'
import logo from "../../src/img/octopimpLOGO.png";
// import nodepic from "../../src/img/node.png";
// import expresspic from "../../src/img/express.png";
// import reactpic from "../../src/img/react.png";
// import mysqlpic from "../../src/img/mysql.png";
// import mongopic from "../../src/img/mongo.png";


export default function Home() {
  return (

      <>
        <div className="container appcontent pt-5">
            <div className="row pt-5 mt-5">
              <div className="row col-sm-12 col-md-8 col-lg-6 ml-auto mr-auto mt-3 mb-3">
                <h2 className="left m-4">Our highly versatile team offers a proven method of 
                modernizing your online presence.</h2>
              </div>
              <div className="center col-sm-12 col-md-8 col-lg-6 ml-auto mr-auto mt-4 mb-5 glass2 p-4 shadow1 border1 huey">
                <h5 className="text-light justify">Under the KICKapps umbrella of 
                services you will find what your team needs, we invite you to explore our existing products 
                below, or schedule a consultation for a custom tailored solution for your organization.</h5>
              </div>
              
              <div className="col-12 center pb-4">
                <Link to="/products" role="button" className="col-lg-auto col-md-5 col-sm-5 col-xs-5 btn btn-t btn-md shadow1 mb-4 ml-auto mr-auto">Our Products</Link>
               &nbsp;&nbsp;&nbsp;&nbsp;
                <Link to="/contact" role="button" className="col-lg-auto col-md-5 col-sm-5 col-xs-5 btn btn-b btn-md shadow1 mb-4 ml-auto mr-auto">Schedule Consult</Link>
              </div>
              <div className="row center">
              <img className="col ml-auto mr-auto col-8 center" src={logo} alt="KICKapps"></img>
                {/* <p id="quotekey" className="text-white ml-auto mr-auto col-10 center">"We are constantly learning and perfecting new libraries, frameworks and technologies." -Justin Moore | Co-founder</p> */}
            </div> 
              <div className="col-8 card center p-3 mb-5">
                <p className="text-black fs-500">
                Our mission is to help businesses large and small by providing them with an efficient and user-friendly purchase order process at an affordable price. We believe that our software can save businesses time and money, and we are committed to providing the best possible customer service.
                
                </p>
              </div>
            </div>
            
        </div>
      <div className="clouds fixed"></div>
      <div className='bodybkg fixed'></div>
      <div className="stars fixed"></div>
      <div className="twinkling fixed"></div>
    </>

   );
  }