import React from "react"
import { Link } from "react-router-dom"
import brand from '../img/poflow/poflowlogo.png'
import Carousel1 from '../components/Carousel1/index'

export default function Products() {
  return (
    <>
   <div className="appcontent container-100 pt-5">
        <h1 className="text-light center mt-5 pt-5">Products</h1>
        <div className="col-8 center mt-5 mb-5">
            <div className="glass2">
                <p className="text-light fs-500 p-4">
                KickApps LLC has been developing and marketing software since 2020. In that time, we have created a number of applications, but our flagship product is POFlow. It will save your organization time and money by keeping the entire flow of every purchase order digital until the final step. This saves paper and ink waste, time wasted, correspondence issues and delays, and minimizes errors through approvals prior to being printed or emailed out. 
                
                </p>
            </div>
        </div>
        <div className="row center">
          <img className="col-lg-4 col-md-auto col-sm-auto col-xs-auto center" src={brand} alt="PO flow Branding" />
        </div>
        <div className="center m-4 row">
        
          <div className="col col-lg-4 col-sm-12 col-xs-12 p-3">
            
            <div className="card border1 pt-3 pb-3">
              <h5 className="m-3">Let us help free you from paper and save time in the process!</h5>


                    <p className="m-4">POflow allows your purchase order process to be quick and efficient. It is also VERY user friendly and most of all it is cost effective.

                    The price depends on how many users and affords flexibility for large quantity and educational entities.

                    Find out NOW how we can help you shed the paper and time drain of internal purchase orders, or you can get more details about it with one of our sales representatives via video meeting or phone call.</p>
                    <span className="col-auto">
                      
                        <h4 className="center pt-3 pb-4">Request a&nbsp;
                        
                        <Link to="/contact" role="button" className="btn btn-d btn-md shadow1">DEMO</Link></h4>
                    </span>
              </div>
            
          </div>
          <div className="col-lg-8 col-xs-12 order-xs-1 order-md-2 order-lg-2 center p-3">
            
            <div className="mb-5">
            {/* <img src={showcase} className="pic border1 rounded shadow" alt="PO flow" /> */}
            <Carousel1 />
            
            </div>
            
          </div>
        </div>
   </div>
    </>
  );
}
