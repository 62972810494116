import React from 'react';
import '../App.css';

// import calipic from "../../src/img/cali.png";
// import colopic from "../../src/img/colo.png";
// import tennpic from "../../src/img/tenn.png";

export default function Contact() {
  return (
      
        <div className="appcontent container pt-5 pb-4">
          <h1 className="text-light center mt-5 pt-5">Contact Us</h1>
            <div className="row">
                <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-5'>
                  <div className='card shadow-lg border'>
                    
                    <h3 className="col center mt-5 ">
                      <p className="center txt-second">Email us at:&nbsp;&nbsp;</p>
                      <a className="center text-dark" href="mailto:info@kickapps.com">info@kickapps.io</a>
                    </h3>

                    
                    <h2 className="col txt-prime center mt-5 mb-5">
                      <p className="center">Call us at:&nbsp;&nbsp;</p>
                      <a className="center text-dark" href="tel:951-933-9085">951.933.9085</a>
                    </h2>
                  </div>
                 
                </div>

                <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-5 glass2 shadow-lg pb-4'>
                  {/* <div className='glass shadow-lg border'></div> */}
                  <form action="mailto:info@kickapps.io" method="post" enctype="text/plain">
                    <div className="center p-4">
                      <div className="col">
                        <input type="text" name="name" placeholder="Name*" className="round1 m-2" required/>
                          
                          <div className="row">
                            <div className="col center mt-3">
                              <input type="text" name="email" placeholder="Email*" className="round1 m-2" required/>
                                </div>
                                </div>
                                <div className="row">
                            <div className="col center mt-4">
                            <input type="tel" id="phone" name="phone" placeholder="Phone*" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required />
                            <br />
                            <small className="text-secondary">*123-456-7890</small>
                                </div>
                                </div>
                                <div className="row">
                                  <div className="col center mt-2">
                                    <textarea type="text" name="comment" rows="4" cols="22" placeholder="Comment" className="rounded m-2" />
                                    
                                      <br />
                                      <input type="submit" className="m-2" value=" Send " />
                                      <input type="reset" className="m-2" value=" Reset " />
                                      </div>
                                </div>
                            </div>
                        </div>
                    </form>
              </div>
            </div>
            






        </div>
      
     
    );
  }

