import React from 'react';
import Header from './components/Header'
import Main from './components/Main';
import Footer from './components/Footer';
import HttpsRedirect from 'react-https-redirect';
// import Canvas from './components/Canvas'
import { BrowserRouter as Router } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


export default function App() {
  return (
    <div className="App grid-container bkgglass">
      
      <div className="App-head">
        <div className='content-wrap'>
        <HttpsRedirect>
          <Router>
                <Header />
                <div id="maincontent">
                  <Main />
                </div>
                <Footer />
          </Router>
        </HttpsRedirect>
        </div>
      </div>
    
      
    </div>
  );
}