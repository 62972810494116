import React from "react";


import gitbrad from "../../src/img/gitbrad.jpg";
import gitjustin from "../../src/img/gitjustin.jpg";
import gitderek from "../../src/img/gitderek.jpg";

export default function About() {
  
  return (
    <div className="container-100 appcontent pt-5">
    <h1 className="text-light center mt-5 pt-5">About Us</h1>
      
      <div className="col-10 center pt-3">
        

        <div className="row">
          
          <div className="col-lg-4 col-md-6 col-sm-auto col-xs-auto mb-5 glass2 center">
            <img
              className="avatarcard mt-5"
              title="Brad Johnston"
              alt="Brad Johnston"
              src={gitbrad}
            ></img>
            <h3 className="text-white mt-3">Brad Johnston</h3>
            <div className="text-white border1 mb-5 pt-3">
            <p>Company Founder</p>
            <p>20 years in IT Administration</p>
            <p>UI | UX Design and Development</p>
            <p>Full Stack Certified</p>
            <p>React, JSX, HTML5, CSS3</p>
            <p>Illustrator, Photoshop, InDesign, XD</p>
            <p>Progressive Web Applications Specialist</p>
            <p>Product Design</p>
            <div className="border1 p-3 txt-second left">After graduating the Full Stack developer course at UCR Riverside 
            I am now a Full Stack developer with Front End tendencies. In the Lead UI/UX designer role, I am never one to stop learning 
            or creating. As a motivated self-starter I am a founder to the core. Always a team player and natural leader, I graduated 
            the Leadership Dekalb Class of 2010. I strive to give the client AND the user what they want.</div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-auto col-xs-auto mb-5 glass2 center">
            <img
              className="avatarcard mt-5"
              title="Justin Moore"
              alt="Justin Moore"
              src={gitjustin}
            ></img>
            <h3 className="text-white mt-3">Justin Moore</h3>
            <div className="text-white border1 mb-5 pt-3">
            <p>Company Founder</p>
            <p>Software Server and Database Design</p>
            <p>Full Stack Certified</p>
            <p>BackEnd Developer</p>
            <p>JavaScript, Node, Express, React</p>
            <p>AWS Experience</p>
            <p>Object Oriented Programming</p>
            <p>Product Development</p>
            <div className="border1 p-3 txt-second left">After completing a Web Development program through Denver University, 
            I was immediately recruited into a TA position. During my time as a TA, I have filled many roles from 
            tutoring to substitute teaching, and observed web development from numerous viewpoints. My history of 
            educating new and seasoned programmers alike has given me the necessary insight to ensure all KICKapps products 
            are intuitive, regardless the complexity of the job needing done.</div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-auto col-xs-auto mb-5 glass2 center">
            <img
              className="avatarcard mt-5"
              title="Derek Minney"
              alt="Derek Minney"
              src={gitderek}
            ></img>
            <h3 className="text-white mt-3">Derek Minney</h3>
            <div className="text-white border1 mb-5 pt-3">
            <p>Company Founder</p>
            <p>MBA</p>
            <p>Customer Relationship Management Experience</p>
            <p>Full Stack Certified</p>
            <p>MySQL, MongoDB, MariaDB</p>
            <p>Secure Financial Database Design</p>
            <p>Credit card billing database specialist</p>
            <p>Product Deployment</p>
            <div className="border1 p-3 txt-second left">After earning a business degree with a concentration in marketing, I set my sites on obtaining multiple programming 
            certifications. This education, partnered with a vast work experience for some of the most recognizable brands in 
            the world, has given me the insight necessary to ensure the clients and users alike retain a tailor made product, 
            built to their specifications.</div>
            </div>
          </div>

          

        </div>

        
      </div>
    </div>
  );
}
