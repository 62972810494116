import React from "react";
import { Link } from 'react-router-dom';
// import octo from "../../src/img/logowht320.png";
// import logo from "../../src/logo1a.png";
import "../App.css";




export default function Navbar() {
    

  return (




 <nav className="navbar navbar-expand-lg" >
  
  <div className="navgroup" id="navgroup">
  
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" title="Menu"></span>
    </button>
    
    <div className="collapse navbar-collapse" id="navbarCollapse">
     
      <ul className="navbar-nav me-auto mb-2 mb-md-0 center">
        <li className="nav-item nav-item-first">
          <Link className="nav-link nav-link-first" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about">About</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/products">&nbsp;Products&nbsp;</Link>
        </li>
        <li className="nav-item nav-item-last">
          <Link className="nav-link nav-link-last" to="/contact" >Contact</Link>
        </li>
      </ul>
    </div>
 

  

  </div>
</nav> 



)
}



{/* <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
  
</nav> */}